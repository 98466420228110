<i18n>
{
  "en": {
    "delete-title": "Delete contact",
    "personal": "Personal Data",
    "personal-input-1": "Name",
    "personal-input-2": "Last name",
    "personal-input-3": "Gender",
    "personal-input-4": "Birthdate",
    "personal-input-5": "Email",
    "personal-input-6": "Phone number",
    "personal-input-7": "Phone number (secondary)",
    "personal-input-8": "Document",
    "personal-input-9": "Related company",
    "social": "Social media",
    "social-input-1": "LinkedIn",
    "social-input-2": "Instagram",
    "social-input-3": "Facebook",
    "social-input-4": "Twitter",
    "address": "Address",
    "address-input-1": "Zip Code",
    "address-input-2": "Street",
    "address-input-3": "Number",
    "address-input-4": "District",
    "address-input-5": "Complement",
    "address-input-6": "City",
    "address-input-7": "State",
    "address-input-8": "Country",
    "bank": "Bank Data",
    "bank-input-1": "Bank name",
    "bank-input-2": "Bank code",
    "bank-input-3": "Agency",
    "bank-input-4": "Account",
    "bank-input-5": "Account type",
    "bank-input-6": "Account recipient",
    "bank-input-7": "Recipient document",
    "custom-fields": "Custom Fields",
    "attachments": "Attachments",
    "activities": "Activities",
    "created-at": "Created at",
    "updated-at": "Updated at",
    "update": "Save updates",
    "update-mobile": "Save",
    "gender-type-1": "male",
    "gender-type-2": "female"
  },
  "pt-BR": {
    "delete-title": "Deletar contato",
    "personal": "Dados Pessoais",
    "personal-input-1": "Nome",
    "personal-input-2": "Sobrenome",
    "personal-input-3": "Gênero",
    "personal-input-4": "Data de nascimento",
    "personal-input-5": "E-mail",
    "personal-input-6": "Celular principal",
    "personal-input-7": "Celular secundário",
    "personal-input-8": "Documento",
    "personal-input-9": "Empresa relacionada",
    "social": "Mídias sociais",
    "social-input-1": "LinkedIn",
    "social-input-2": "Instagram",
    "social-input-3": "Facebook",
    "social-input-4": "Twitter",
    "address": "Localização",
    "address-input-1": "CEP",
    "address-input-2": "Rua",
    "address-input-3": "Número",
    "address-input-4": "Bairro",
    "address-input-5": "Complemento",
    "address-input-6": "Cidade",
    "address-input-7": "Estado",
    "address-input-8": "País",
    "bank": "Dados Bancários",
    "bank-input-1": "Nome do banco",
    "bank-input-2": "Código",
    "bank-input-3": "Agência",
    "bank-input-4": "Conta",
    "bank-input-5": "Tipo de conta",
    "bank-input-6": "Favorecido da conta",
    "bank-input-7": "Documento do favorecido",
    "custom-fields": "Campos Customizados",
    "attachments": "Anexos",
    "activities": "Atividades",
    "created-at": "Criado em",
    "updated-at": "Última atualização",
    "update": "Salvar alterações",
    "update-mobile": "Salvar",
    "gender-type-1": "masculino",
    "gender-type-2": "feminino"
  }
}
</i18n>

<template>
  <modal-entity
    class="contact"
    @close="closeContact"
    @drop="drop"
    :menuItems="menuItems"
    @currentMenuItem="currentMenuItem = $event"
    :loading="loading"
  >
    <template #actions>
      <confirm-modal
        :open="confirmDeleteOpened"
        @confirm="deleteContact(contact.id)"
        @close="confirmDeleteOpened = false"
      />
      <we-icon-button
        :icon="['far', 'trash-alt']"
        :name="$t('delete-title')"
        @click="confirmDeleteOpened = true"
        direction="bottom"
        cy="delete-contact"
        class="big-mobile"
      />
      <we-icon-button icon="times" class="big-mobile" @click="closeContact" />
    </template>
    <template #data>
      <div class="data-grid">
        <we-picture-change
          type="square"
          size="79"
          :name="contact.first_name"
          :picture="contact.picture"
          :loading="imgLoading"
          @onFileChange="onFileChange"
          @delete="deleteContactImage(contact.id)"
        />
        <div class="data-info">
          <we-text-tooltip
            :text="`${contact.first_name} ${contact.last_name}`"
            class="name"
          />
          <div class="data-list">
            <span class="email">{{ contact.email }}</span>
          </div>
        </div>
      </div>
    </template>
    <template #body>
      <v-form ref="form">
        <section
          v-if="!loading"
          id="profile"
          name="profile"
          :class="{ 'hide-mobile': currentMenuItem != 'profile' }"
        >
          <div class="title">
            <div class="icon">
              <font-awesome-icon icon="user" />
            </div>
            <span class="section-name">{{ $t("personal") }}</span>
          </div>
          <div class="fields">
            <we-input
              v-model="contact.first_name"
              isRequired
              type="text"
              :label="$t('personal-input-1')"
            />
            <we-input
              type="text"
              v-model="contact.last_name"
              isRequired
              :label="$t('personal-input-2')"
            />
            <we-input
              :label="$t('personal-input-3')"
              type="tag"
              v-model="contact.gender"
              :items="[$t('gender-type-1'), $t('gender-type-2')]"
            />
            <we-input
              v-model="contact.birthdate"
              type="date"
              :label="$t('personal-input-4')"
            />
            <we-input
              class="fill-input"
              v-model="contact.email"
              type="email"
              :label="$t('personal-input-5')"
            />
            <we-input
              v-model="contact.phone1"
              type="text"
              :label="$t('personal-input-6')"
              :mask="[$root.$t('phone-mask'), $root.$t('phone-second-mask')]"
            />
            <we-input
              v-model="contact.phone2"
              type="text"
              :label="$t('personal-input-7')"
              :mask="[$root.$t('phone-mask'), $root.$t('phone-second-mask')]"
            />
            <we-input
              v-if="$i18n.locale === 'pt-BR'"
              type="text"
              label="CPF"
              v-model="contact.brazilian_document_cpf"
              :mask="$root.$t('cpf-mask')"
            />
            <we-input
              v-if="$i18n.locale === 'pt-BR'"
              type="text"
              label="RG"
              mask="####################"
              v-model="contact.brazilian_document_rg"
            />
            <we-input
              type="text"
              :label="$t('personal-input-8')"
              v-model="contact.document"
            />
            <we-input-companies
              :label="$t('personal-input-9')"
              :value="contact.company_id"
              @input="contact.company_id = $event.id"
            />
          </div>
        </section>
        <section
          id="social"
          name="social"
          :class="{ 'hide-mobile': currentMenuItem != 'social' }"
        >
          <div class="title">
            <div class="icon">
              <font-awesome-icon icon="hashtag" />
            </div>
            <span class="section-name">{{ $t("social") }}</span>
          </div>
          <div class="fields">
            <we-input
              v-model="contact.social_media_linkedin"
              type="text"
              :label="$t('social-input-1')"
            />
            <we-input
              v-model="contact.social_media_instagram"
              type="text"
              :label="$t('social-input-2')"
            />
            <we-input
              v-model="contact.social_media_facebook"
              type="text"
              :label="$t('social-input-3')"
            />
            <we-input
              v-model="contact.social_media_twitter"
              type="text"
              :label="$t('social-input-4')"
            />
          </div>
        </section>
        <section
          id="address"
          name="address"
          :class="{ 'hide-mobile': currentMenuItem != 'address' }"
        >
          <div class="title">
            <div class="icon">
              <font-awesome-icon icon="globe-americas" />
            </div>
            <span class="section-name">{{ $t("address") }}</span>
          </div>
          <div class="fields">
            <we-input
              v-model="contact.address_zipcode"
              type="text"
              :label="$t('address-input-1')"
              :mask="$root.$t('zipcode-mask')"
              @blur="getCep($event)"
            />
            <we-input
              v-model="contact.address_street"
              type="text"
              :label="$t('address-input-2')"
              :loading="cepLoading"
            />
            <we-input
              v-model="contact.address_number"
              :mask="'######'"
              type="text"
              :label="$t('address-input-3')"
            />
            <we-input
              v-model="contact.address_block"
              type="text"
              :label="$t('address-input-4')"
              :loading="cepLoading"
            />
            <we-input
              v-model="contact.address_note"
              type="text"
              :label="$t('address-input-5')"
            />
            <we-input
              v-model="contact.address_city"
              type="text"
              :label="$t('address-input-6')"
              :loading="cepLoading"
            />
            <we-input
              v-model="contact.address_state"
              type="text"
              :label="$t('address-input-7')"
              :loading="cepLoading"
            />
            <we-input
              v-model="contact.address_country"
              type="text"
              :label="$t('address-input-8')"
              :loading="cepLoading"
            />
          </div>
        </section>
        <section
          id="bank"
          name="bank"
          :class="{ 'hide-mobile': currentMenuItem != 'bank' }"
        >
          <div class="title">
            <div class="icon">
              <font-awesome-icon
                :icon="{ prefix: 'far', iconName: 'credit-card' }"
              />
            </div>
            <span class="section-name">{{ $t("bank") }}</span>
          </div>
          <div class="fields">
            <we-input
              v-model="contact.bank_name"
              type="text"
              :label="$t('bank-input-1')"
            />
            <we-input
              v-model="contact.bank_code"
              type="text"
              :mask="'######'"
              :label="$t('bank-input-2')"
            />
            <we-input
              v-model="contact.bank_branch"
              type="text"
              :mask="[
                $root.$t('bank-agency-mask-1'),
                $root.$t('bank-agency-mask-2')
              ]"
              :label="$t('bank-input-3')"
            />
            <we-input
              v-model="contact.bank_account"
              type="text"
              mask="###################"
              :label="$t('bank-input-4')"
            />
            <we-input
              :label="$t('bank-input-5')"
              type="select"
              v-model="contact.bank_account_type"
              :items="['Corrente', 'Poupança']"
            />
            <we-input
              :label="$t('bank-input-6')"
              type="select"
              v-model="contact.bank_account_owner"
              :items="['Sim', 'Não']"
            />
            <we-input
              v-model="contact.bank_account_owner_document"
              type="text"
              :label="$t('bank-input-7')"
              :mask="[$root.$t('cpf-mask'), $root.$t('cnpj-mask')]"
            />
          </div>
        </section>
        <section
          id="custom-fields"
          name="custom-fields"
          :class="{ 'hide-mobile': currentMenuItem != 'custom-fields' }"
        >
          <div class="title">
            <div class="icon">
              <font-awesome-icon icon="folder-plus" />
            </div>
            <span class="section-name">{{ $t("custom-fields") }}</span>
          </div>
          <div class="fields">
            <we-input
              v-for="(field, i) in customFieldsInputs"
              :key="i"
              :placeholder="field.placeholder"
              :type="field.type"
              :label="field.label"
              :items="field.values"
              :isRequired="field.is_required"
              :readonly="field.is_read_only"
              v-model="contactCustomFields[field.name]"
              :mask="field.mask"
              :class="field.layout"
            />
          </div>
        </section>
      </v-form>
      <section
        id="attachments"
        name="attachments"
        :class="{ 'hide-mobile': currentMenuItem != 'attachments' }"
      >
        <div class="title">
          <div class="icon">
            <font-awesome-icon icon="paperclip" />
          </div>
          <span class="section-name">{{ $t("attachments") }}</span>
        </div>
        <div class="fields fill">
          <drop-file
            ref="dropFileComponent"
            entity="contact"
            :recordId="contact.id"
          />
        </div>
      </section>
      <section
        id="activities"
        name="activities"
        :class="{ 'hide-mobile': currentMenuItem != 'activities' }"
      >
        <div class="title">
          <div class="icon">
            <font-awesome-icon icon="tasks" />
          </div>
          <span class="section-name">{{ $t("activities") }}</span>
        </div>
        <div class="fields fill">
          <activities
            ref="activitiesComponent"
            entity="contact"
            :recordId="contact.id"
            relatedDeals
          />
        </div>
      </section>
    </template>
    <template #bottom>
      <div class="history">
        <div class="icon">
          <font-awesome-icon icon="history" />
        </div>
        <div class="data">
          <div class="item">
            {{ $t("created-at") }}:
            <span>{{ contact.created_at | dateTime }}</span>
          </div>
          <div class="item">
            {{ $t("updated-at") }}:
            <span>{{ contact.updated_at | dateTime }}</span>
          </div>
        </div>
      </div>
      <we-button
        @click="updateContact(contact)"
        class="green mobile-icon"
        :text="$t('update')"
        :mobile-text="$t('update-mobile')"
        icon="check"
        :loading="btnLoading"
      />
    </template>
  </modal-entity>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      confirmDeleteOpened: false,
      loading: true,
      btnLoading: false,
      imgLoading: false,
      picture: {},
      cepLoading: false,
      searchLoading: false,
      firstEvent: true,
      debounce: null,
      reloadContacts: false,
      currentMenuItem: "profile",
      selectedCompanies: []
    };
  },
  computed: {
    menuItems() {
      return [
        {
          id: "profile",
          name: this.$t("personal"),
          icon: "user"
        },
        {
          id: "social",
          name: this.$t("social"),
          icon: "hashtag"
        },
        {
          id: "address",
          name: this.$t("address"),
          icon: "globe-americas"
        },
        {
          id: "bank",
          name: this.$t("bank"),
          icon: ["far", "credit-card"]
        },
        {
          id: "custom-fields",
          name: this.$t("custom-fields"),
          icon: "folder-plus"
        },
        {
          id: "attachments",
          name: this.$t("attachments"),
          icon: "paperclip"
        },
        {
          id: "activities",
          name: this.$t("activities"),
          icon: "tasks"
        }
      ];
    },
    contact() {
      return this.$store.getters.getCurrentContact;
    },
    customFieldsInputs() {
      return this.$store.getters.getContactCustomFieldsInputs;
    },
    contactCustomFields() {
      return this.$store.getters.getContactCustomFields;
    },
    mapPosition() {
      return {
        lat: parseFloat(this.contact.address_latitude),
        lng: parseFloat(this.contact.address_longitude)
      };
    },
    currentCompany() {
      return this.$store.getters.getCurrentCompany;
    },
    companyId() {
      return this.contact?.company_id;
    }
  },
  methods: {
    ...mapActions([
      "contactRequest",
      "updateContactRequest",
      "deleteContactRequest",
      "updateContactPictureRequest",
      "deleteContactPictureRequest",
      "cepRequest",
      "customFieldsRequest",
      "companyRequest"
    ]),
    updateSelectedCompanies(val) {
      this.selectedCompanies = val;
      this.contact.company_id = val?.id || null;
    },
    async updateContact(contact) {
      this.btnLoading = true;

      this.reloadContacts = true;
      contact.custom_fields = [this.contactCustomFields];
      await this.updateContactRequest(contact);
      this.btnLoading = false;
    },
    async deleteContact(id) {
      this.loading = true;
      this.reloadContacts = true;
      await this.deleteContactRequest(id);
      this.loading = false;
      this.closeContact();
    },
    async onFileChange(e) {
      const file = e.target.files[0];
      this.picture = file;
      this.imgLoading = true;
      this.reloadContacts = true;
      await this.updateContactPictureRequest(this.picture);
      this.imgLoading = false;
    },
    async deleteContactImage() {
      this.imgLoading = true;
      this.reloadContacts = true;
      await this.deleteContactPictureRequest(this.contact.id);
      this.imgLoading = false;
    },
    async getCep(number) {
      if (number.length === 9) {
        this.cepLoading = true;
        await this.cepRequest(number.split("-").join(""));
        this.cepLoading = false;
      }
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    drop(event) {
      this.$refs.dropFileComponent.onChange(event.dataTransfer.files);
    },
    closeContact() {
      this.$router.push({
        name: "contacts",
        query: { reload: this.reloadContacts }
      });
    },
    async doRequests() {
      await this.contactRequest(this.$route.params.contactId);
      await this.customFieldsRequest();
      await this.$refs.activitiesComponent.allNotesRequest();
      await this.$refs.dropFileComponent.allFilesRequest();
      if (this.companyId) {
        await this.companyRequest(this.companyId);
      }
      this.loading = false;
      setTimeout(() => {
        this.$refs.form.validate();
      }, 100);
    }
  },
  async mounted() {
    await this.doRequests();

    document.title = `${this.contact.first_name} ${this.contact.last_name}`;

    if (this.companyId) {
      this.selectedCompanies = this.currentCompany;
    }
  }
};
</script>

<style lang="scss"></style>
