<i18n>
{
  "en": {
    "title": "New Contact",
    "input-1": "Name",
    "input-2": "Last name",
    "input-3": "Email",
    "input-4": "Phone",
    "input-5": "Related company",
    "create": "Create contact",
    "custom-fields": "Campos customizados"
  },
  "pt-BR": {
    "title": "Novo Contato",
    "input-1": "Nome",
    "input-2": "Sobrenome",
    "input-3": "Email",
    "input-4": "Número de celular",
    "input-5": "Empresa relacionada",
    "create": "Criar contato",
    "custom-fields": "Custom fields"
  }
}
</i18n>

<template>
  <modal-action @close="closeCreateContact" :columns="!hasCustomFields ? 1 : 2">
    <template #view>
      <div class="modal">
        <div class="modal-header">
          <div class="name">
            <div class="icon">
              <font-awesome-icon icon="user-plus" />
            </div>
            {{ $t("title") }}
          </div>
          <div class="options">
            <we-icon-button icon="times" @click="closeCreateContact" />
          </div>
        </div>
        <div class="modal-body">
          <v-form ref="form" v-model="createValid">
            <div class="fields">
              <we-input
                v-model="contact.first_name"
                cy="create-contact-first-name"
                isRequired
                type="text"
                :label="$t('input-1')"
              />
              <we-input
                v-model="contact.last_name"
                cy="create-contact-last-name"
                type="text"
                :label="$t('input-2')"
              />
              <we-input
                v-model="contact.email"
                cy="create-contact-email"
                type="email"
                :label="$t('input-3')"
              />
              <we-input
                v-model="contact.phone1"
                type="text"
                :label="$t('input-4')"
                :mask="[$root.$t('phone-mask'), $root.$t('phone-second-mask')]"
              />
              <we-input
                v-model="contact.social_media_linkedin"
                type="text"
                label="LinkedIn"
              />
              <we-input-companies
                :label="$t('input-5')"
                :value="contact.company_id"
                @input="contact.company_id = $event.id"
              />
              <we-input
                v-if="hasCustomFields"
                class="double-column"
                type="div"
                :placeholder="$t('custom-fields')"
              />
              <we-input
                v-for="(field, i) in customFieldsRequired"
                :key="i"
                :type="field.type"
                :label="field.label"
                :items="field.values"
                :isRequired="field.is_required"
                :readonly="field.is_read_only"
                :placeholder="field.placeholder"
                v-model="contactCustomFields[field.name]"
                :mask="field.mask"
                :class="field.layout"
                cy="create-contact-custom-field"
              />
            </div>
          </v-form>
        </div>
        <div class="bottom">
          <we-button
            class="green"
            cy="create-contact"
            :text="$t('create')"
            icon="plus"
            :loading="loadingBtn"
            @click="createContact"
            :disabled="!createValid"
          />
        </div>
        <we-loading-overflow :loading="loading" />
      </div>
    </template>
  </modal-action>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      createValid: false,
      loadingBtn: false,
      loading: true,
      contact: {
        first_name: "",
        last_name: "",
        email: "",
        phone1: "",
        social_media_linkedin: "",
        company_id: 0
      }
    };
  },
  computed: {
    customFieldsInputs() {
      return this.$store.getters.getContactCustomFieldsInputs;
    },
    customFieldsRequired() {
      return this.customFieldsInputs.filter(e => e.is_required);
    },
    contactCustomFields() {
      return this.$store.getters.getContactCustomFields;
    },
    hasCustomFields() {
      return this.customFieldsRequired.length > 0;
    }
  },
  methods: {
    ...mapActions(["createContactRequest", "customFieldsRequest"]),
    closeCreateContact() {
      if (this.$route.name.includes("createDealContact")) {
        this.$router.push({
          name: "pipelineDeal",
          params: { dealId: this.$route.params.dealId }
        });

        return;
      }

      this.$router.push({ name: "contacts" });
    },
    async createContact() {
      this.loadingBtn = true;
      const payload = {
        ...this.contact,
        custom_fields: [this.contactCustomFields]
      };
      const response = await this.createContactRequest(payload);

      this.loadingBtn = false;

      if (this.$route.name.includes("createDealContact")) {
        this.$router.push({
          name: "pipelineDeal",
          params: { dealId: this.$route.params.dealId, contact: response.data }
        });

        return;
      }

      if (response?.data?.id) {
        this.$router.push({
          name: "contact",
          params: { contactId: response.data.id },
          query: { reload: true }
        });
      }
    }
  },
  async mounted() {
    this.$store.commit("clearContactCustomFieldsValues");
    await this.customFieldsRequest();
    this.loading = false;
  }
};
</script>
