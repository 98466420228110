<i18n>
{
  "en": {
    "title": "Contacts",
    "search": "Search for name, email, document...",
    "create": "New contact",
    "create-mobile": "New",
    "filter": "Filter",
    "export-results": "Export results",
    "edit-filtered": "Edit filtered data",
    "delete-filtered": "Delete filtered data",
    "as-csv": "as CSV",
    "reload": "Reload",
    "export": "Export",
    "export-all": "Export all",
    "import": "Import",
    "not-found": "Contacts not found",
    "not-found-description": "Check your search or filter again or click on button below to create a new one",
    "column-1": "Name",
    "column-2": "Phone",
    "column-3": "Document",
    "column-4": "Created at",
    "column-5": "Updated at",
    "call": "Call",
    "call-error-title": "Wasn't possible to call!",
    "call-error-description": "This contact don't have phone number.",
    "whatsapp-error-title": "Wasn't possible to open Whatsapp!",
    "whatsapp-error-description": "This contact don't have phone number."
  },
  "pt-BR": {
    "title": "Contatos",
    "search": "Pesquise pelo nome, email, CPF...",
    "create": "Novo contato",
    "create-mobile": "Novo",
    "filter": "Filtro",
    "export-results": "Exportar resultados",
    "edit-filtered": "Editar registros filtrados",
    "delete-filtered": "Deletar registros filtrados",
    "as-csv": "como CSV",
    "reload": "Recarregar",
    "export": "Exportar",
    "export-all": "Exportar tudo",
    "import": "Importar",
    "not-found": "Nenhum contato encontrado",
    "not-found-description": "Verifique sua pesquisa novamente ou clique no botão abaixo para criar",
    "column-1": "Nome",
    "column-2": "Telefone",
    "column-3": "CPF",
    "column-4": "Criado em",
    "column-5": "Última atualização",
    "call": "Ligar",
    "call-error-title": "Não foi possível ligar!",
    "call-error-description": "Esse contato não possui número de telefone registrado.",
    "whatsapp-error-title": "Não foi possível abrir o Whatsapp",
    "whatsapp-error-description": "Esse contato não possui número de telefone registrado."
  }
}
</i18n>

<template>
  <div id="contacts">
    <nav-top :title="$t('title')" :returnPage="!isDesktop ? 'records' : null">
      <template #top-content>
        <we-input
          type="search"
          cy="search-in-contacts"
          v-model="search"
          @input="searchContacts"
          :inputLabel="$t('search')"
        ></we-input>
        <we-button
          class="green mobile-icon"
          cy="create-contact-cta"
          :text="$t('create')"
          :mobile-text="$t('create-mobile')"
          icon="user-plus"
          @click="$router.push({ name: 'createContact' })"
        />
      </template>
    </nav-top>
    <board-list class="contacts">
      <router-view />
      <template #filters>
        <we-pagination :pages="contactsPages" @change="selectPage($event)" />
        <we-button
          class="mobile-icon"
          :class="filterActive ? 'primary' : 'disabled'"
          :text="$t('filter')"
          icon="sliders-h"
          @click="$router.push({ name: 'filterContact' })"
          cy="filter-in-contacts"
        />
        <we-balloon v-if="filterActive">
          <template #action>
            <we-icon-button
              icon="download"
              :name="$t('export-results')"
              color="icon-primary"
            />
          </template>
          <template #balloon>
            <div class="balloon-item" @click="exportFilteredFile('csv')">
              <font-awesome-icon icon="file-csv" />
              <div class="text">
                {{ $t("export-results") }} <b>{{ $t("as-csv") }}</b>
              </div>
            </div>
          </template>
        </we-balloon>
        <we-icon-button
          v-if="filterActive"
          :icon="['fas', 'edit']"
          :name="$t('edit-filtered')"
          @click="massUpdateOpened = true"
          cy="update-filtered-contacts"
        />
        <mass-update
          :open="massUpdateOpened"
          @close="massUpdateOpened = false"
          @confirm="doRequests"
          :total-records="contactsPages.total"
        />
        <we-icon-button
          v-if="filterActive"
          :icon="['far', 'trash-alt']"
          :name="$t('delete-filtered')"
          color="icon-red"
          @click="confirmDeleteOpened = true"
          cy="delete-filtered-contacts"
        />
        <confirm-modal
          :open="confirmDeleteOpened"
          :total-records="contactsPages.total"
          @confirm="deleteFilteredData()"
          @close="confirmDeleteOpened = false"
        />
      </template>
      <template #actions>
        <we-icon-button
          icon="sync-alt"
          cy="reload-contacts"
          @click="doRequests(true)"
          :name="$t('reload')"
        />
        <we-balloon>
          <template #action>
            <we-icon-button
              icon="download"
              :name="$t('export')"
              cy="export-contacts-trigger"
            />
          </template>
          <template #balloon>
            <div class="balloon-item" @click="exportFile('csv')">
              <font-awesome-icon icon="file-csv" />
              <div class="text" cy="export-contacts">
                {{ $t("export-all") }} <b>{{ $t("as-csv") }}</b>
              </div>
            </div>
          </template>
        </we-balloon>
        <we-icon-button
          icon="file-upload"
          @click="openImport"
          :name="$t('import')"
          cy="import-contacts"
        />
      </template>
      <template #list>
        <we-loading-overflow :loading="loadingContacts" />
        <div class="labels">
          <div
            class="label"
            :class="
              elem.orderBy === contactsSort.orderBy
                ? contactsSort.direction
                : ''
            "
            v-for="elem in sortElements"
            :key="elem.orderBy"
            @click="orderBy(elem)"
          >
            <we-text-tooltip class="name" :text="elem.name" lines="1" />
            <div class="icon">
              <font-awesome-icon icon="sort-up" />
              <font-awesome-icon icon="sort-down" />
            </div>
          </div>
        </div>
        <div class="items">
          <we-not-found v-if="contacts.length === 0">
            <template #title> {{ $t("not-found") }} </template>
            <template #description>
              {{ $t("not-found-description") }}
            </template>
            <template #actions>
              <we-button
                class="green"
                :text="$t('create')"
                icon="user-plus"
                @click="$router.push({ name: 'createContact' })"
              />
            </template>
          </we-not-found>
          <div
            class="card-list"
            v-for="(contact, i) in contacts"
            :key="i"
            @click="openContact(contact.id)"
          >
            <div class="column profile">
              <we-rounded-picture
                :picture="contact.picture"
                :name="contact.first_name"
                :text="
                  $options.filters.firstLetters(
                    `${contact.first_name} ${contact.last_name}`
                  )
                "
                size="36"
              />
              <div class="data">
                <we-text-tooltip
                  class="name"
                  :text="`${contact.first_name} ${contact.last_name}`"
                  lines="1"
                />
                <we-text-tooltip
                  class="email"
                  :text="contact.email"
                  lines="1"
                />
              </div>
            </div>
            <div class="column only-desktop">
              <we-text-tooltip
                class="email"
                :text="contact.phone1 || '-'"
                lines="1"
              />
            </div>
            <div class="column only-desktop">
              <we-text-tooltip
                class="name"
                :text="
                  $i18n.locale === 'en'
                    ? contact.document
                    : $options.filters.cpf(contact.brazilian_document_cpf)
                "
                lines="1"
              />
            </div>
            <div class="column only-desktop">
              <we-text-tooltip
                class="name"
                :text="$options.filters.dateTime(contact.created_at)"
                lines="1"
              />
            </div>
            <div class="column only-desktop">
              <we-text-tooltip
                class="name"
                :text="$options.filters.dateTime(contact.updated_at)"
                lines="1"
              />
            </div>
            <div class="column only-mobile">
              <we-button
                class="primary inherit mobile-icon"
                :text="$t('call')"
                icon="phone"
                @click="callContact(contact)"
              />
            </div>
            <div class="column only-mobile">
              <we-button
                class="green inherit mobile-icon"
                text="Whats"
                :icon="['fab', 'whatsapp']"
                @click="messageContact(contact)"
              />
            </div>
          </div>
        </div>
      </template>
    </board-list>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      massUpdateOpened: false,
      confirmDeleteOpened: false,
      createValid: false,
      loadingBtn: false,
      loadingContacts: true,
      initialPage: 1,
      search: "",
      sortElements: [
        {
          name: this.$t("column-1"),
          orderBy: "first_name",
          direction: "desc"
        },
        {
          name: this.$t("column-2"),
          orderBy: "phone1",
          direction: "desc"
        },
        {
          name: this.$t("column-3"),
          orderBy: "document",
          direction: "desc"
        },
        {
          name: this.$t("column-4"),
          orderBy: "created_at",
          direction: "desc"
        },
        {
          name: this.$t("column-5"),
          orderBy: "updated_at",
          direction: "desc"
        }
      ]
    };
  },
  computed: {
    contacts() {
      return this.$store.getters.getContacts;
    },
    contactsPages() {
      return this.$store.getters.getContactsPages;
    },
    contactsSort() {
      return this.$store.getters.getContactsSort;
    },
    filterActive() {
      return this.$store.getters.getFilterActive;
    },
    exportParams() {
      return this.$store.getters.getExportParams;
    }
  },
  methods: {
    ...mapActions([
      "contactsRequest",
      "createContactRequest",
      "searchContactsRequest",
      "filterContactsRequest",
      "exportRequest",
      "exportFilteredAsCsvRequest",
      "deleteFilteredRecords"
    ]),
    async deleteFilteredData() {
      this.loadingContacts = true;
      await this.deleteFilteredRecords();
      await this.doRequests();
    },
    callContact(contact) {
      if (contact.phone1.length > 0) {
        let formatedPhone = this.$options.filters.clearPhoneNumber(
          contact.phone1
        );
        window.open(`tel:+55${formatedPhone}`);
      } else {
        const snackBar = {
          title: this.$t("call-error-title"),
          description: this.$t("call-error-description"),
          status: "error"
        };

        this.$store.commit("setSnackBar", snackBar);
        this.$store.commit("addSnackBar");
      }
    },
    messageContact(contact) {
      if (contact.phone1.length > 0) {
        let formatedPhone = this.$options.filters.clearPhoneNumber(
          contact.phone1
        );
        window.open(`https://wa.me/55${formatedPhone}`, "_blank");
      } else {
        const snackBar = {
          title: this.$t("whatsapp-error-title"),
          description: this.$t("whatsapp-error-description"),
          status: "error"
        };

        this.$store.commit("setSnackBar", snackBar);
        this.$store.commit("addSnackBar");
      }
    },
    exportFile() {
      const payload = {
        entity: "contacts"
      };

      this.exportRequest(payload);
    },
    exportFilteredFile(ext) {
      if (ext === "csv") {
        this.exportFilteredAsCsvRequest("contacts-filtered");
      }
    },
    openImport() {
      this.$router.push({ name: "importContacts" });
    },
    searchContacts() {
      this.loadingContacts = true;
      this.$store.commit("setFilterActive", false);
      let orderBy = {};
      if (this.contactsSort.orderBy) {
        orderBy = {
          orderBy: this.contactsSort.orderBy,
          direction: this.contactsSort.direction
        };
      }
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.search?.length > 0) {
          await this.searchContactsRequest({
            value: this.search,
            page: 1,
            ...orderBy
          });
        } else {
          await this.doRequests();
        }
        this.loadingContacts = false;
      }, 600);
    },
    openContact(id) {
      this.$router.push({ name: "contact", params: { contactId: id } });
    },
    async orderBy(elem) {
      this.loadingContacts = true;

      if (elem.direction === "desc") {
        elem.direction = "asc";
      } else {
        elem.direction = "desc";
      }

      this.$store.commit("setContactsSort", elem);

      let orderBy = {};
      if (elem.orderBy) {
        orderBy = {
          orderBy: elem.orderBy,
          direction: elem.direction
        };
      }

      if (this.filterActive) {
        await this.filterContactsRequest({
          filters: this.exportParams?.payload?.filters,
          page: this.contactsPages.current_page,
          ...orderBy
        });
      } else if (this.search.length > 0) {
        await this.searchContactsRequest({
          value: this.search,
          page: this.contactsPages.current_page,
          paginate: true,
          ...orderBy
        });
      } else {
        await this.contactsRequest({
          page: this.contactsPages.current_page,
          updateLocal: false,
          ...orderBy
        });
      }
      this.loadingContacts = false;
    },
    async doRequests(reset) {
      this.loadingContacts = true;

      if (reset) {
        this.contactsPages.current_page = this.initialPage;
        this.$store.commit("setFilterActive", false);
        this.$store.commit("setContactsSort", {
          orderBy: "",
          direction: "",
          name: ""
        });
      }
      if (this.filterActive) {
        await this.filterContactsRequest({
          filters: this.exportParams?.payload?.filters,
          page: this.contactsPages.current_page
        });
      } else if (this.search.length > 0) {
        await this.searchContactsRequest({
          value: this.search,
          page: this.contactsPages.current_page,
          paginate: true
        });
      } else {
        await this.contactsRequest({
          page: this.contactsPages.current_page,
          updateLocal: false
        });
      }

      this.loadingContacts = false;
    },
    async selectPage(val) {
      this.loadingContacts = true;
      let orderBy = {};
      if (this.contactsSort.orderBy) {
        orderBy = {
          orderBy: this.contactsSort.orderBy,
          direction: this.contactsSort.direction
        };
      }

      if (this.filterActive) {
        await this.filterContactsRequest({
          filters: this.exportParams?.payload?.filters,
          page: val,
          ...orderBy
        });
      } else if (this.search.length > 0) {
        await this.searchContactsRequest({
          value: this.search,
          page: val,
          ...orderBy
        });
      } else {
        await this.contactsRequest({
          page: val,
          ...orderBy,
          updateLocal: false
        });
      }

      this.loadingContacts = false;
    }
  },
  async mounted() {
    await this.doRequests(true);
    this.$store.commit("resetFilters");
  },
  beforeRouteUpdate(to, from, next) {
    if (to.hash.length > 0) {
      return;
    }
    if (from.name === "filterContact") {
      this.search = "";
    }
    if (from.name === "contact" || from.name === "importContacts") {
      if (!to.name === "contactDeal") {
        this.doRequests();
      }
    }
    if (to.query.reload) {
      this.doRequests();
    }
    next();
  }
};
</script>

<style lang="scss">
#contacts {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: 69px calc(100vh - 69px);
  position: relative;

  @include mobile {
    display: block;
  }
}
</style>
